import styled from "styled-components";
import Navbar from "./Navbar";

const Contenedor = styled.div`
  background-image: linear-gradient(
  170deg,
  hsl(240deg 3% 6%) 44%,
  hsl(240deg 2% 7%) 78%,
  hsl(240deg 2% 8%) 86%,
  hsl(240deg 1% 8%) 90%,
  hsl(240deg 1% 9%) 92%,
  hsl(240deg 1% 10%) 93%,
  hsl(240deg 0% 10%) 95%,
  hsl(240deg 0% 11%) 96%,
  hsl(240deg 0% 11%) 98%,
  hsl(0deg 0% 12%) 100%
    );
    width: 100%;

`
const ContenedorImagen = styled.div`
    margin: 0 auto;
    width: 90%;
`
const Imagen = styled.img`
    width: 100%;
    max-height: 1500px;
    object-fit: cover;
`


const Estandar = ({ imagenes }) => {

    return (<>
        <Contenedor>
            <Navbar />
            {imagenes.map((imagen, index) => (
                <ContenedorImagen>
                    <Imagen key={index} src={imagen} alt={`Imagen ${index}`} loading="lazy" />
                </ContenedorImagen>
            ))}
        </Contenedor>
    </>)
}

export default Estandar;