import portadaBodas from "./bodas/portadaBodas.jpg"
import bf1 from "./bodas/foto1.jpg"
import bf2 from "./bodas/foto2.jpg"
import bf3 from "./bodas/foto3.jpg"
import portadaGastronomia from "./gastronomia/portadaGastronomia.jpg"
import gf1 from "./gastronomia/foto1.jpg"
import gf2 from "./gastronomia/foto2.jpg"
import gf3 from "./gastronomia/foto3.jpg"
import gf4 from "./gastronomia/foto4.jpg"
import gf5 from "./gastronomia/foto5.jpg"
import gf6 from "./gastronomia/foto6.jpg"
import portadaPaisajes from "./Paisajes/portadaPaisaje.jpg"
import pf1 from "./Paisajes/foto1.jpg"
import pf2 from "./Paisajes/foto2.jpg"
import pf3 from "./Paisajes/foto3.jpg"
import portadaRetratos from "./Retratos/portadaRetratos.png"
import rf1 from "./Retratos/foto1.jpg"
import rf2 from "./Retratos/foto2.jpg"
import rf3 from "./Retratos/foto3.jpg"
import rf4 from "./Retratos/foto4.jpg"
import rf5 from "./Retratos/foto5.jpg"
import rf6 from "./Retratos/foto6.jpg"
import rf7 from "./Retratos/foto7.jpg"
import portadaShows from "../Assets/shows/Verticales/portadaShows.jpg"
import sf1 from "../Assets/shows/Verticales/foto1.jpg"
import sf2 from "../Assets/shows/Verticales/foto2.jpg"
import sf3 from "../Assets/shows/Verticales/foto3.jpg"
import sf4 from "../Assets/shows/Verticales/foto4.jpg"
import sf5 from "../Assets/shows/Verticales/foto5.jpg"
import sf6 from "../Assets/shows/Verticales/foto6.jpg"
import sf7 from "../Assets/shows/Verticales/foto7.jpg"




export const bodas = [
    portadaBodas,
    bf1,
    bf2,
    bf3
];

export const gastronomia = [
    portadaGastronomia, gf1,
    gf2,
    gf3,
    gf4,
    gf5,
    gf6
];

export const paisajes = [
    portadaPaisajes,
    pf1,
    pf2,
    pf3,
];
export const retratos = [
    portadaRetratos,
    rf1,
    rf2,
    rf3,
    rf4,
    rf5,
    rf6,
    rf7
];

export const shows = [
    portadaShows,
    sf1,
    sf2,
    sf3,
    sf4,
    sf5,
    sf6,
    sf7
];


export const Portadas = [
    portadaBodas,
    portadaGastronomia,
    portadaPaisajes,
    portadaRetratos,
    portadaShows

]

export const Titulos = ["bodas", "gastronomia", "paisajes", "retratos", "shows"]


export const listaCanciones = [
    {
        nombre: "After - Luces",
        url: "https://www.youtube.com/watch?v=IJY181_59E0"
    },
    {
        nombre: "Coda atipica",
        url: "https://www.youtube.com/watch?v=Dw29SHZ5nZg"
    },
    {
        nombre: "IVAN - BANDIDO",
        url: "https://www.youtube.com/watch?v=uUJBLzr3HBM"
    },
    {
        nombre: "Gambler - Tripolar",
        url: "https://www.youtube.com/watch?v=LO5Pjg3EoNc"
    },
    {
        nombre: "Berenice - 50 Boys",
        url: "https://www.youtube.com/watch?v=hQlwN4N64s8"
    },
    {
        nombre: "Evric hunter - Al final de la noche",
        url: "https://www.youtube.com/watch?v=xBWCMrJeFJU"
    },
    {
        nombre: "Coda Ft Xante - Fanatica",
        url: "https://youtu.be/ycJlSfopvWo"
    },
    {
        nombre: "Gese X Serk - Ya es tarde",
        url: "https://youtu.be/EcoYCQwJH-w"
    },
    {
        nombre: "Florci - Tu veneno",
        url: "https://youtu.be/gwg30N3SBuY"
    },
    {
        nombre: "Rich cuts FT Creed wey - Amor de flaites",
        url: "https://youtu.be/nJE2uMEF4CM"
    },
    {
        nombre: "Doxa - Giro",
        url: "https://youtu.be/68ci40u4RVY"
    },
    {
        nombre: "Young Sam - Ella llama",
        url: "https://youtu.be/5qbMENJd6nw"
    },
    {
        nombre: "Alessandra - Resurgir",
        url: "https://youtu.be/FcCUEgf0ebA"
    },
    {
        nombre: "Rommpe FT Manitto - Que no pare",
        url: "https://youtu.be/MjQgpQFivZc"
    }
];
