import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './components/Home';
import Fotografias from './components/Fotografias';
import Videos from './components/Videos';
import Contacto from './components/Contacto';
import { bodas, gastronomia, paisajes, retratos, shows } from './Assets/Info';
import Estandar from './components/pequeños/Estandar';


function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/fotografias' element={<Fotografias />} />
          <Route path='/fotografias/bodas' element={<Estandar imagenes={bodas} />} />
          <Route path='/fotografias/gastronomia' element={<Estandar imagenes={gastronomia} />} />
          <Route path='/fotografias/paisajes' element={<Estandar imagenes={paisajes} />} />
          <Route path='/fotografias/retratos' element={<Estandar imagenes={retratos} />} />
          <Route path='/fotografias/shows' element={<Estandar imagenes={shows} />} />
          <Route path='/videos' element={<Videos />} />
          <Route path='/contacto' element={<Contacto />} />
          <Route path='*' element={<Navigate to={'/'} replace />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
