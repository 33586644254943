import styled from "styled-components"
import Navbar from "./pequeños/Navbar"

import React from "react"
import { Portadas } from "../Assets/Info"
import { Titulos } from "../Assets/Info"
import { useNavigate } from "react-router-dom"



const Contenedor = styled.div`
  background-image: linear-gradient(
  170deg,
  hsl(240deg 3% 6%) 44%,
  hsl(240deg 2% 7%) 78%,
  hsl(240deg 2% 8%) 86%,
  hsl(240deg 1% 8%) 90%,
  hsl(240deg 1% 9%) 92%,
  hsl(240deg 1% 10%) 93%,
  hsl(240deg 0% 10%) 95%,
  hsl(240deg 0% 11%) 96%,
  hsl(240deg 0% 11%) 98%,
  hsl(0deg 0% 12%) 100%
    );
    width: 100%;
    height: 100vh;


`




const FotoContenedor = styled.section`
    position: relative;
    width: 100%;
    background-image: url(${props => props.image});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    transition: all .3s ease-in-out;
     :hover{
        box-shadow: inset 0px 0px 15px 0px rgba(255,255,255,1);
    }
    @media (min-width:20.00em) {     
        height: 300px;
     
  }

  @media (min-width:37.500em) {
   height: 500px;
    
    
  }
  @media(min-width:44rem) {
   height: 600px;
   
         
  }
  @media(min-width:61.93em) {
    height: 100%;
  } 
`
const Pantalla = styled.div`
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgba(224, 224, 224, 0.0);
    justify-content: center;
    align-items: center;
    display: hidden;
    z-index: 1;
    transition: all .3s ease-in-out;
     :hover{
        box-shadow: inset 0px 0px 15px 0px rgba(255,255,255,1);
    }
`

const Boton = styled.a`
    color: white;
    font-size: 1.2rem;
    letter-spacing: 3px;
    padding-left: 45px;
    padding-right: 45px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-family: 'Roboto', sans-serif; 
    text-transform: uppercase;
    background-color: transparent;
    border: 2px solid white;
    z-index: 1;
    :hover{
        background-color: white;
        transition: all .5s ease-in-out;
        color: black;
        font-weight: 500;
        border: 2px solid rgba(255,255,255,.2);
    }
    
`

const Fotografias = () => {
    const navigate = useNavigate();
    const cambiaRuta = (path) => {
        navigate(path);
    }


    return (<>
        <Contenedor>
            <Navbar />
            {Portadas.map((image, index) => (<>
                <FotoContenedor image={image}>
                    <Pantalla>
                        <Boton onClick={() => cambiaRuta(`${Titulos[index]}`)} >{Titulos[index]}</Boton>
                    </Pantalla>
                </FotoContenedor>

            </>))}

        </Contenedor>
    </>)
}

export default Fotografias