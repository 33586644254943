import React from "react";
import styled, { keyframes } from "styled-components";
import logo from "../../Assets/logo.png"


const LoaderContainer = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    animation: name duration timing-function delay iteration-count direction fill-mode;
    animation: salida 1s linear 2s 1 forwards;

    @keyframes salida {

        0% {
		opacity: 1;
		transform: scale(1);
	}

	100% {
		opacity: 0;
		transform: scale(0.6);
	}
        
    }
   
`
const BolasContainer = styled.div`
    width: 100%;
    display: flex;
    height: 100px;
    justify-content: center;
    align-items: center;
    gap: 50px;
`

const Bola1 = styled.div`
    width: 1.5rem;
    height: 1.5rem;
    background-color: #f3f3f3;
    border-radius: 50%;
    opacity: 0;
    animation: movimiento 2s linear 0s infinite  forwards;

    @keyframes movimiento {
        0%{
            opacity: 0.1;
            transform: scale(0.5);
        }
        
        25%{
             opacity: 0.5;
             transform: scale(1);
        }

        50%{
            opacity: 0.5;
            transform: scale(1.5);
        }

         75%{
             opacity: 0.5;
             transform: scale(1);
        }
        100%{
             opacity: 0.1;
            transform: scale(0.5);
        }
       
    }
`
const Bola2 = styled.div`
    width: 1.5rem;
    height: 1.5rem;
    background-color: #f3f3f3;
    border-radius: 50%;
    animation: movimiento 2s linear 0s infinite  forwards;
    opacity: 0;
    @keyframes movimiento {
        0%{
            opacity: 0.1;
            transform: scale(0.5);
        }
        
        25%{
             opacity: 0.5;
             transform: scale(1);
        }

        50%{
            opacity: 0.5;
            transform: scale(1.5);
        }

         75%{
             opacity: 0.5;
             transform: scale(1);
        }
        100%{
             opacity: 0.1;
            transform: scale(0.5);
        }
       
    }
`
const Bola3 = styled.div`
    width: 1rem;
    height: 1rem;
    background-color: #f1f1f1;
    border-radius: 50%;
    width: 1.5rem;
    height: 1.5rem;
    background-color: #f3f3f3;
    border-radius: 50%;
    animation: movimiento 2s linear 0s infinite  forwards;

    @keyframes movimiento {
        0%{
            opacity: 0.1;
            transform: scale(0.5);
        }
        
        25%{
             opacity: 0.5;
             transform: scale(1);
        }

        50%{
            opacity: 0.5;
            transform: scale(1.5);
        }

         75%{
             opacity: 0.5;
             transform: scale(1);
        }
        100%{
             opacity: 0.1;
            transform: scale(0.5);
        }
       
    }
`
const LogoContainer = styled.div`
     
  
    img{
        height: 200px;
    } 
`

const Loading = () => {


    return (<>

        <LoaderContainer>
            <LogoContainer>
                <img src={logo} alt="" />
            </LogoContainer>
            <BolasContainer>
                <Bola1 />

                <Bola2 />
                <Bola3 />
            </BolasContainer>
        </LoaderContainer>

    </>)
}

export default Loading;