import styled from "styled-components";
import Navbar from "./pequeños/Navbar";
import uriel from "../Assets/uriel.jpg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faTiktok } from "@fortawesome/free-brands-svg-icons";

const Contenedor = styled.div`
   
    background-image: linear-gradient(
  170deg,
  hsl(240deg 3% 6%) 44%,
  hsl(240deg 2% 7%) 78%,
  hsl(240deg 2% 8%) 86%,
  hsl(240deg 1% 8%) 90%,
  hsl(240deg 1% 9%) 92%,
  hsl(240deg 1% 10%) 93%,
  hsl(240deg 0% 10%) 95%,
  hsl(240deg 0% 11%) 96%,
  hsl(240deg 0% 11%) 98%,
  hsl(0deg 0% 12%) 100%
    );
    width: 100%;
    overflow: hidden;
   

`
const Titulo = styled.h2`
    white-space: nowrap;
    color: white;
    text-align: center;
    font-size: 1.9rem;
    font-family: 'Roboto', sans-serif; 
    font-weight: 300;
    text-transform: uppercase;
    padding-top: 40px;
    padding-bottom: 40px;
    letter-spacing: 5px;

`
const ContenedorContacto = styled.div`
    width: 100%;
    padding: auto;
    display: flex;
    flex-direction: column;
 
`

const ContenedorImg = styled.div`
  margin: 0 auto;
  width: 95%;
  max-width: 400px;
`

const Imagen = styled.img`
    width: 100%;
    border-bottom-left-radius: 1rem ;
    border-bottom-right-radius: 1rem;
   border-top-right-radius: .5rem;
   border-top-left-radius: 0.5rem;
`

const Parrafo = styled.p`
    color: white;
    font-family: 'Roboto', sans-serif; 
    text-align: center;
    margin: 20px;
    text-transform: uppercase;
    line-height: 25px;
    font-weight: 100;
    letter-spacing: 2px;

`


const ContenedorSocial = styled.div`
    padding: 10px;
    width: 100%;
    height: 100px;
    justify-content: center;
    display: flex;
    margin-bottom: 30px;
    
`
const Icono = styled(FontAwesomeIcon)`
    font-size: 1.5rem;
    color: white;
    padding: 30px;
    margin: 0 30px;
    cursor: pointer;
    transition: all 0.3s ease-in;
    :hover{
        transform: scale(1.1) translateY(-5px);
        box-shadow: 0px 0px 15px -3px rgba(255,255,255,0.2);
    }
   @media (min-width:25.00em) {     
     
     font-size: 1.9rem;
    
  }

  @media (min-width:37.500em) {
   
    font-size: 2.5rem;
    
  }

`
const Contacto = () => {

    return (<>
        <Contenedor>
            <Navbar />
            <Titulo>CONTACTO</Titulo>
            <ContenedorContacto>
                <ContenedorImg>
                    <Imagen src={uriel} alt="Uriel Villalva" />
                    <Parrafo>Podes contactarme para tu proyecto enviando un mensaje a cualquiera de mis redes sociales</Parrafo>
                </ContenedorImg>
            </ContenedorContacto>
            <ContenedorSocial>
                <a href="https://instagram.com/yalva_av?igshid=MzRlODBiNWFlZA==" target="new:blank"><Icono icon={faInstagram} /></a>
                <a href="mailto:yalva.av@gmail.com" target="new:blank"><Icono icon={faEnvelope} /></a>
                <a href="https://www.tiktok.com/@yalva.av?_t=8cwlt4BL6k2&_r=1" target="new:blank"><Icono icon={faTiktok} /></a>
            </ContenedorSocial>
        </Contenedor>


    </>)
}

export default Contacto;