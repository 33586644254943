import styled from "styled-components";
import Navbar from "./pequeños/Navbar";
import Loading from "./pequeños/Loading";
import { useEffect, useState } from "react";

const Contenedor = styled.div`
  background-image: linear-gradient(
  170deg,
  hsl(240deg 3% 6%) 44%,
  hsl(240deg 2% 7%) 78%,
  hsl(240deg 2% 8%) 86%,
  hsl(240deg 1% 8%) 90%,
  hsl(240deg 1% 9%) 92%,
  hsl(240deg 1% 10%) 93%,
  hsl(240deg 0% 10%) 95%,
  hsl(240deg 0% 11%) 96%,
  hsl(240deg 0% 11%) 98%,
  hsl(0deg 0% 12%) 100%
    );
    width: 100%;
    height: 100vh;
    overflow-x: hidden;


`

const Videos = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simula un tiempo de carga
        setTimeout(() => {
            setLoading(false);
        }, 3000);
    }, []);

    return (<>

        <Contenedor>
            <Navbar />

            {loading ? (
                <Loading />
            ) :
                <>
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/Dw29SHZ5nZg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/IJY181_59E0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/uUJBLzr3HBM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/LO5Pjg3EoNc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/hQlwN4N64s8" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/xBWCMrJeFJU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/ycJlSfopvWo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/LO5Pjg3EoNc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/EcoYCQwJH-w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/nJE2uMEF4CM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/gwg30N3SBuY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/nJE2uMEF4CM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/68ci40u4RVY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/5qbMENJd6nw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/FcCUEgf0ebA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/MjQgpQFivZc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </>
            }







        </Contenedor>


    </>)
}

export default Videos